<template>
  <base-section id="k-d-a-consult-review" space="100" top-inner-shadow>
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col>
          <component
            :is="m_objTitle.strComponent"
            :class="`kda-ts-${g_bLowerBr ? '24' : '64'}pt wt-extrabold text-center`"
          >
            {{ m_objTitle.strText }}
          </component>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        :style="{ gap: g_bLowerBr ? '0px' : '56px' }"
        :no-gutters="g_bLowerBr"
      >
        <v-col v-for="project in m_arrProjectsLoaded" :key="project.strTitle" cols="auto">
          <v-card
            class="px-5 py-5"
            max-width="500"
            height="100%"
            no-outlined
            color="#FFF"
            elevation="0"
          >
            <base-img
              :src="project.imgSrc"
              cover
              style="max-height: 280px; max-width: 100%; border-radius: 8px"
              class="py-4"
            />

            <div class="py-4">
              <h4 class="kda-ts-28pt wt-extrabold mb-4">{{ project.strTitle }}</h4>
              <component
                :is="project.strComponent"
                :class="`kda-ts-${g_bLowerBr ? 14 : 16}pt ln-150 nunito main-bw-300--text`"
                v-html="project.htmlDesc"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';
import TextClass from '@/mixins/text-class';

export default {
  name: 'KDAConsultReview',

  mixins: [TextClass, BaseBreakpoint],

  data() {
    return {
      iProjectIndex: 0
    };
  },

  computed: {
    m_strButtonMore() {
      return 'Lihat Lebih Banyak Proyek';
    },
    m_objTitle() {
      return {
        strText: this.$vuetify.lang.t(`$vuetify.kda.consult.review`),
        strComponent: 'h1'
      };
    },
    m_arrProjectsLoaded() {
      const result = [
        {
          strTitle: 'Way Finder Project',
          htmlDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-wayfinder.quote'),
          strComponent: 'p'
        },
        {
          strTitle: 'AXA Project',
          htmlDesc: this.$vuetify.lang.t('$vuetify.kda.project.detail.item-axa.quote'),
          strComponent: 'p'
        }
      ];

      for (let i = 0; i < result.length; i++) {
        result[i].imgSrc = require(`@/assets/photos/home-project-${i + 1}.png`);
      }

      return result.slice(0, this.length);
    }
  }
};
</script>
